import React, { useState, useEffect, Suspense, Fragment, useRef, ReactComponent } from "react";
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { CSSTransition } from 'react-transition-group';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import AspectRatioBox from "./aspectRatioUtil";
import TextRow from "./textRow";
import Loader from '../static/svg/loader.svg';
import { ReactComponent as ArrowUp } from "../static/svg/arrow-white.svg";
import SwiperCore, { Navigation, Pagination, Mousewheel, EffectFade, Autoplay, FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/mousewheel';
import 'swiper/css/effect-fade';

import classNames from 'classnames';

import configData from "../config.json";


SwiperCore.use([Autoplay])
gsap.registerPlugin(ScrollTrigger);

function Case(props) {

  function getMedia(path, path_m) {
    let result = props.isMobile ? (path_m ? path_m : path) : path;
    return configData.API_URL + result;
  }
  

  
    let {caseSlug} = useParams();

    const [loaded, setLoaded] = useState(false);
    const [imageTypes, setImageTypes] = useState(['.webp','.png'])
    const [cases, setCases] = useState();
    const [caseRows, setCaseRows] = useState([]);
    const [caseRowIndex, setCaseRowIndex] = useState();
    const [obj, setObj] = useState();
    const [industries, setIndustries] = useState();
    const [recentWorkIntro, setRecentWorkIntro] = useState();
    const [values, setValues] = useState();
    const [clientIntro, setClientIntro] = useState();
    const [peopleIntro, setPeopleIntro] = useState();
    const [swipers, setSwipers] = useState([]);
    const [headingAr, setHeadingAr] = useState();

    const mediaArray = useRef([]);

    function getFileType(string) {
      let fileType;
      var ext = string.split('.').pop();
      switch (ext.toLowerCase()) {
        case "gif":
          fileType = "img";
          break;
        case "png":
          fileType = "img";
          break;
        case "jpg":
          fileType = "img";
          break;
        case "jpeg":
          fileType = "img";
          break;
        case "webp":
          fileType = "img";
          break;
        case "mp4":
          fileType = "vid";
          break;
        case "webm":
          fileType = "vid";
          break;
        case "svg":
          fileType = "svg";
          break;
        default:
          break;
      }
      return fileType;
    }

    const getCaseRow = async(caseId) => {
      await axios({
        method: 'get',
        url: configData.API_URL + "/api/case-row/" + caseId + '/'
        })
        .then(response => {
          const dataJSON = response.data;
          let rows = [...caseRows];
          rows[caseRowIndex]['caserowitems'] = dataJSON.caserowitems
          setCaseRows(rows);
          // console.log('caseRow updated', rows)
        });

        let elemId = "caseRow_" + caseRowIndex;
        function scrollLoad() {
        //  console.log('scrollLoad')
          let rowTl = gsap.timeline({
            defaults: {
              duration: 0, 
              ease: "power1",
            },
            scrollTrigger: {
              trigger: "#" + elemId,
              start: "center bottom",
              end: "bottom bottom",
              scrub: 0,
              markers: false,
              onEnter: () => { setCaseRowIndex(caseRowIndex + 1) }
            }
          });

          let rowTlAnim = gsap.timeline({
            defaults: {
              duration: 0.4, 
              ease: "power1"
            },
            scrollTrigger: {
              trigger: "#caseRowBox_" + caseId,
              start: "top center+=35%",
              end: "bottom bottom",
              scrub: 1,
              markers: false
            }
          });

          let boxObj = document.getElementById('caseRowBox_' + caseId);
          rowTlAnim.fromTo(boxObj, {translateY: "40px"}, {translateY: "0"});
          rowTlAnim.fromTo(boxObj, {opacity: 0}, {opacity: 1},"<");
        }
        scrollLoad();  
    }

    const getObj = async() => {
        await axios({
          method: 'get',
          url: configData.API_URL + "/api/case/" + caseSlug
          })
          .then(response => {
            const dataJSON = response.data;
            console.log('case', dataJSON)
            setObj(dataJSON);
          });
      }

    useEffect(() => {
        if (caseRowIndex > -1) {
          // console.log('caseRow index', caseRowIndex) 
          if (obj.caserows[caseRowIndex]) {
            getCaseRow(obj.caserows[caseRowIndex].id)
          }
        }
    }, [caseRowIndex])

    useEffect(() => {
        if (obj) {
            if (obj.white_logo) {
              props.logoColor('fillWhite');
            } else {
              props.logoColor('fillBlack');
            }
            setLoaded(true)
            obj.caserows && setCaseRows(obj.caserows);
            setCaseRowIndex(0);
            // console.log('case study loaded', caseSlug, obj, obj.caserows);

            let landingAnim = gsap.timeline({
              defaults: {
                duration: 0.4, 
                ease: "power1"
              },
              scrollTrigger: {
                trigger: "#anim0",
                start: "top bottom",
                end: "bottom bottom",
                scrub: 1,
                markers: false
              }
            });
  
            let boxObj = document.getElementById('anim0');
            if (boxObj) {
              landingAnim.fromTo(boxObj, {translateY: "40px"}, {translateY: "0"});
              landingAnim.fromTo(boxObj, {opacity: 0}, {opacity: 1},"<");
            }
            

            props.accentColor(obj.grid_color);

            function getHeadingAr() {
              let w;
              if (obj.heading_aspect_ratio) {
                w = (obj.heading_aspect_ratio/window.innerWidth) * 100
          
              } else {
                w = (64/window.innerWidth) * 100

              }
              setHeadingAr(w + '%');
            }
            getHeadingAr();

        }
    }, [obj])

    useEffect(() => {
        caseSlug && getObj();
    }, [caseSlug])


    useEffect(() => {
      props.menuColor('none');
      props.accentColor('#FFFFFF');
      console.log('case study props', props)

      // Page Meta
      document.title = ""
      document.querySelector('meta[name="description"]').setAttribute("content", "");
      document.documentElement.scrollTop = 0;

      return () => {
        swipers.forEach(obj => {
          obj.destoy();
        })
      }

    }, []);

    function addSwiper(obj) {
      let currSwipers = [ ...swipers ];
      currSwipers.push(obj);
      setSwipers(currSwipers);
    }


    function getFile(id, path, fileType) {
      
      if (!mediaArray.current.includes(path)) {

        let span = document.getElementById('span_' + id);
        if (span) {
          
          let format = span.parentElement.dataset.format;
          let elemCount = span.parentElement.dataset.count;
          let count = elemCount;
          // console.log('elemCount', elemCount);
          if (elemCount === '4') {
            count = 2;
            // console.log('count', count);
          } 

          let copy = [ ...mediaArray.current ];
          copy.push(path);
          mediaArray.current = copy;
          // span.style.width = "800px"
        }

        if ((fileType === '.mp4') || (fileType === '.webm')) { 
          let target = document.getElementById(id);
          if (target) {
            setTimeout(() => {
              target.parentElement.play();
            }, 500);
            target.parentElement.parentElement.parentElement.classList.add('loaded');
          }
         
        } else {

          const getMediaFile = async (url) => {
            // console.log('mediaArray.current', mediaArray.current, mediaArray.current.includes(url))
            const response = await fetch(url);
            
            const blob = await response.blob() 
            return blob
            
          }
        
          getMediaFile(path)
              .then(result => {
                let target = document.getElementById(id);
                // console.log('result', "#" + id)
                const imageBlob = result
                const imageBase64 = URL.createObjectURL(imageBlob)
                target.setAttribute('src', imageBase64);
                target.parentElement.parentElement.classList.add('loaded');
              })
              .catch(err => {
                // log the specific error
                console.error(`Error -> ${err}`)
              })
          }
      }
    }

  function getFormat(format, format_m) {
    let r;
    if (props.isMobile) {
      r = format_m;
    } else {
      r = format
    }
    // console.log('r', props.isMobile, format, format_m)
    return r;
  }

  function handleCaption(e) {
    let target = e.target;
    // console.log('btn', target);
    if (target.classList.contains('open')) {
      target.classList.remove('open');
    } else {
      target.classList.add('open');
    }
  }

  function getCaseRowCaseName(row) {
    let format = getFormat(row.format, row.format_m);
    let result;
    switch (format) {
      case "auto":
        result = "items items" + row.itemsCount
        break;
      case "grid4":
        result = "items grid4"
        break
      default:
        result = "items textGrid auto"
        break;
    }
    return result;
  }

  
   

  return (
      <CSSTransition in={loaded} timeout={200} unmountOnExit classNames="pageItem">
      
         <div id="caseContent">
            
            {obj && <div className={`row caseHeadingRow`} id="row0">
                
                <span id="row0trigger" className="animTrigger"></span>
                {(getFileType(obj.file_type) === 'vid') && <video poster={Loader} playsInline muted autoPlay loop><source id={"landingRow_" + obj.id} src={getMedia(obj.file, obj.file_m)} preload={"none"} onLoad={getFile("landingRow_" + obj.id, getMedia(obj.file, obj.file_m), obj.aspect_ratio, obj.aspect_ratio_m, obj.file_type )} type={props.isMobile?"video/mp4":"video/webm"} /></video> }

                {(obj.file && obj.file_type === ".webp") &&  <img src={Loader} className="loading" id={"file_" + obj.id} alt={obj.file_alt} onLoad={getFile("file_" + obj.id, getMedia(obj.file, obj.file_m), obj.file_type)} />}
                
                <div className="column textColumn">
                  <div className={classNames(
                    "textContainer"
                  )}>
                    <div className={'row0'}>

                      {obj && <TextRow fonts={true} h1={obj.heading} h2={obj.subheading} h3={null} p={obj.text} readmore={obj.text_more} /> }   
                
                    </div>

                  </div>
                </div>
                
            </div>}
            <div className="caseRows">               
              {caseRows && caseRows.map((row, i) => (
              <div 
                key={"caseRow_" + i}
                className={classNames(
                  "caseRowBox anim0",
                  (row.text && "textRow")
                )}
                id={"caseRowBox_" + row.id}
              >
                {row.text &&  <div className={`row caseRow`}>
                  <div className={classNames(
                    "textCol ",
                    row.alignment,
                  )}>
                    <div className="caseTrigger" id={"caseRow_" + i}></div>
                    <div className="caseTrigger" id={"caseRowAnim_" + i}></div>
                 
                    
                 
                       <span dangerouslySetInnerHTML={{__html: row.text}}/>
                  
                    
                   
                  </div>
                </div>}
                
                {!row.slider ?
                <div className={classNames(
                  "caseRowContainer",
                  ((row.caserowitems.length > 1)?" grid grid2":" solo"),
                  caseRows[i+1] && caseRows[i+1].text && "hasText"
                )}>
                {row && row.caserowitems.map((item, j) => (
                  <div key={"caseRowItem_" + j} className={"caseRowBoxItem"}>
                    <div className={`row caseRow`}>
                      {item.caption && <span className="caption"><p>{item.caption}</p></span>}
                      {(getFileType(item.file_type) === 'img') && <img src={getMedia(item.file, item.file_m)} alt={item.file_alt} />}
                      {(getFileType(item.file_type) === 'vid') && <video poster={Loader} playsInline muted autoPlay loop><source id={"landingRow_" + item.id} src={getMedia(item.file, item.file_m)} preload={"none"} onLoad={getFile("landingRow_" + item.id, getMedia(item.file, item.file_m), item.file_type )} type={props.isMobile?"video/mp4":"video/webm"}/></video>}
                    </div>
                  
                  </div>
                  ))}
                </div>
                :
                <div className="swiperColumn">
                  <Swiper
                    modules={[Navigation,Pagination,Autoplay]}
                    initialSlide={0}
                    className="swiper-slide"
                    slidesPerView={1}
                    autoplay={{
                      delay: 3000
                    }}
                    speed={1000}
                    loop
                    pagination={false}
                    effect={'fade'}
                    >
                    {row.caserowitems && row.caserowitems.map((item, i) => (
                      <SwiperSlide 
                        key={"work_" + i}
                      >
                      <div className={"caseRowBoxItem"}>
                      <div className={`row caseRow`}>
                        {item.caption && <span className="caption"><p>{item.caption}</p></span>}
                        {(getFileType(item.file_type) === 'img') && <img src={getMedia(item.file, item.file_m)} alt={item.file_alt} />}
                        {(getFileType(item.file_type) === 'vid') && <video poster={Loader} playsInline muted autoPlay loop><source id={"landingRow_" + item.id} src={getMedia(item.file, item.file_m)} preload={"none"} onLoad={getFile("landingRow_" + item.id, getMedia(item.file, item.file_m), item.file_type )} type={props.isMobile?"video/mp4":"video/webm"} /></video>}
                      </div>
                    
                    </div>
                      </SwiperSlide>
                      ))}
                    
                  </Swiper>  
         
                </div>
              }

              </div>
              ))}

      
                  
                    
                  
           
            </div>

            {obj && (obj.cases.length > 0) && 
              <div className="row relatedWork caseIndex">
                <div className="flex">
         
                    <h3><span>Explore</span> other work</h3>
             
                </div>
                  <div className="gridCaseIndex grid">
                        {obj && obj.cases && obj.cases.map((obj, i) => (
                          <Link to={obj.get_absolute_url}
                              data-block={"case" + (i + 1)}
                              key={'case_' + i}
                              id={'caseId_' + obj.id}
                              style={{
                                backgroundColor: obj.grid_color?obj.grid_color:"#1a1a1a",
                                // paddingTop: "100%"
                              }}
                              onClick={() => window.scrollTo(0,0)}
                              className={"item arBox caseIndexBox"}
                              >
                              <span className={"animTrigger"} id={'triggerCaseId_' + obj.id}></span>
                              <span className="arBoxIn">
                                <span className="caseIndexBoxIn">
                                  <img src={getMedia(obj.thumbnail)} alt={obj.image_alt} />
                                  <span className="textRow">
                                    <h2>{obj.name}</h2>
                                  
                                    <div className="learn">
                                    <p>View case study</p>
                                    <ArrowUp className="icon" />
                                    </div>
                                  </span>
                                </span>
                              </span>
                            </Link>
                          ))}
                    </div>     
                          
            
            </div>
            }
           
            {obj && <style>{obj.theme}</style>}
        </div>
      </CSSTransition>
  );
}

export default Case;


// <AspectRatioBox tag={'h2'} classes={['caseHeading']} fonts={props.fonts} content={obj.heading} headingAr={headingAr} />

// {row.heading &&  
//   <div className="heading">
//     <h2><span dangerouslySetInnerHTML={{__html: row.heading}}/></h2> 
//     {row.subheading && <h3><span dangerouslySetInnerHTML={{__html: row.subheading}} /></h3>}
//   </div>
// }